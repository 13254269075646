<template>
  <el-upload ref="upload" name="file" :accept="accept" :multiple="limit > 1"
              :action="action" 
              :data="formData"
              :on-success="uploadSuccess"
              :on-remove="uploadRemove"
              :on-error="uploadError" 
              :on-exceed="uploadExceed"
              :before-upload="uploadBefore"
              :show-file-list="false">
    <slot>
      <img src="../assets/img/jia.png" alt="">
    </slot>
  </el-upload>
</template>
<script>
// 判断两个数组是否相等
function arrayIsEqual(array1, array2) {
  if(array1.length !== array2.length) {
    return false
  }

  let ret = true
  for(let i = array1.length; i >= 0; --i) {
    if(array1[i] !== array2[i]) {
      ret = false
      break
    }
  }
  return ret
}

export default {
  name: 'uploader',
  props: {
    type: {
      type: String,
      default: 'image'
    },
    actionType: {
      type: String,
      default: '1'
    },
    actionUrl: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/gif'
    },
    fileList: [String, Array],
    size: [Number, String],
    widthHeight: [String],
    limit: {
      type: Number,
      default: 50
    },
    tip: {
      type: String,
      default: '请上传'
    }
  },
  data() {
    return {
      waiting: 0,         // 当前等待上传文件数量
      fileNum: 0,         // 当前文件数量（包含待上传，上传中）
      oldFileList: [],    // 已上传完成的文件列表
      formData: {
        diskName: 'pic',    // 存放文件标示 pic-图片，video-视频
        sysId: 1,           // 系统标识: 1.自动售卖机,2.自取式售卖机,3.充电桩	
        organId: '',
        organName: '',
        organPath: '',
        imageHeight: 800,
        imageWidth: 800,
        imageRatio: 1,
        imageCompressType: 1,   // 压缩类型 1-按大小压缩，2-按比例压缩
        format: 'jpg'
      },
    }
  },
  computed: {
    files: {
      get() {
        let fileList = this.$utils.splitStr(this.fileList)
        let isEqual = this.oldFileList && arrayIsEqual(this.oldFileList.map(item => item.src), fileList)
        if(isEqual) {
          return this.oldFileList
        }else{
          return fileList.filter(item => this.$utils.isString(item)).map(item => {
            let index = item.lastIndexOf('/') + 1
            let filePath = item.substring(0, index)
            let fileName = item.substring(index)
            
            return {
              uid: this.$utils.guid(),
              name: fileName,
              src: item,
              url: item,
              // url: this.type === 'image' ? this.$utils.imgThumb(src, 150, 150) : src,
              status: 'success'
            }
          })
        }
      },
      set(value = []) {
        this.oldFileList = value
        this.fileNum = value.length
        let fileList = value.filter(item => item.status === 'success').map(item => item.src)
        let updateFile = fileList.length === 1 ? fileList.join() : fileList
        this.$emit('update:fileList', updateFile)
        this.$emit('change', updateFile)
      }
    },
    action() {
      return 'https://shijie.meiy365.com/pc/ws' + (this.actionUrl || (this.actionType == 1 ? '/sys-fileserver/service/fileCompress' : '/sys-fileserver/service/file'))
    }
  },
  watch: {
    files:{
      immediate: true,
      handler(value = []) {
        // this.oldFileList = value
        this.fileNum = value.length
      }
    }
  },
  methods: {
    uploadSuccess(response, file, fileList) {
      --this.waiting
      let url = response.url || response.data.url || response.data
      if(url) {
        let uploadFile = {
          uid: this.$utils.guid(),
          name: file.name,
          src: url,
          url: url,
          status: 'success'
        }
        if(this.limit > 1) {
          this.files = this.files.concat(uploadFile)
        }else{
          this.files = [uploadFile]
        }
      }else{ // 上传失败
        --this.fileNum
        this.$message.error('上传失败')
      }
    },
    uploadBefore(file) {
      console.log(file)
      if(this.size && (file.size / 1000) > this.size) {
        this.$message.error(`上传文件大小不得超过${this.size}kb`)
        return Promise.reject(`上传文件大小不得超过${this.size}kb`)
      }
      if(file.type.indexOf('image/') > -1) {
        if(this.widthHeight) {
          let widthHeight = this.widthHeight.split('x')
          this.formData.imageWidth = widthHeight[0]
          this.formData.imageHeight = widthHeight[1]
          return true
        }else{
          let that = this
          return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function (e) {
              let data = e.target.result
              //加载图片获取图片真实宽度和高度
              let img = new Image()
              img.onload = function() {
                that.formData.imageWidth = img.width
                that.formData.imageHeight = img.height
                ++that.fileNum
                ++that.waiting

                resolve(file)
              }
              img.onerror = reject
              img.src = data
            }
            reader.onerror = reject
            reader.readAsDataURL(file)
          })
        }
      }else{
        this.$message.error('请选择图片文件')
        return Promise.reject('请选择图片文件')
      }
    },
    uploadRemove(file) {
      if(file.status === 'success') {
        this.files = this.files.filter(item => item.uid !== file.uid)
      }else if(this.waiting > 0){
        --this.waiting
        --this.fileNum
      }
    },
    uploadError(error, file) {
      this.$notify.error({
        title: '上传失败',
        message: '【'+ file.name +'】' + error.message,
        duration: 5000,
      })
    },
    uploadExceed() {
      this.$message.error('最多上传' + this.limit + '张照片')
    }
  },
  mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem('user'))
    if(userInfo) {
      this.formData.organId = userInfo.organId
      this.formData.organName = userInfo.organName
      this.formData.organPath = userInfo.organPath
    }
  },
}
</script>