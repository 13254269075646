<template>
  <div class="join-us">
    <PageHeader :navData="navData"></PageHeader>
    <!-- 内容区域 -->
    <div class="content">
      <div class="table">
        <el-table
          :data="workData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          border
          :span-method="objectSpanMethod"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <h6>岗位描述：</h6>
              <div
                class="recruitRequire"
                v-html="scope.row.recruitDescribe"
              ></div>
              <h6>应聘要求：</h6>
              <div
                class="recruitRequire"
                v-html="scope.row.recruitRequire"
              ></div>
            </template>
          </el-table-column>
          <el-table-column
            prop="recruitPosition"
            :label="$t('recruitPosition')"
          ></el-table-column>
          <el-table-column
            prop="workWages"
            :label="$t('salary')"
          ></el-table-column>
          <el-table-column
            prop="workPlace"
            :label="$t('place')"
          ></el-table-column>
          <el-table-column :label="$t('delivery')" width="120">
            <template slot-scope="scope">
              <el-button size="small" @click="openResumeFun(scope.row)">
                {{ $t('delivery') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="detail-box">
        <!-- 基本信息+简历附件， -->
        <!-- 头像 -->
        <!-- 真实姓名 性别  出生年月  参见工作时间  电话号码 邮箱-->
        <!--  应聘岗位 期望薪资 当前城市 目前状态 ，附件上传 -->
        <!-- 附件提示：拖曳文件到这里，支持doc,docx,pdf,jpg,png格式文件 -->
        <el-dialog
          class="resume"
          :title="$t('DeliverResume')"
          :visible.sync="resume.dialogFalg"
          :close-on-click-modal="false"
          :append-to-body="true"
          :before-close="closeResume"
          width="50%"
        >
          <el-form
            :model="resume.data"
            :rules="rules"
            ref="resume"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item :label="$t('RealName')" prop="username">
              <el-input
                :maxlength="8"
                v-model="resume.data.username"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('JobApplication')" prop="recruitPosition">
              <el-input
                :value="resume.data.recruitPosition"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('sex')" prop="sex">
              <el-radio-group v-model="resume.data.sex">
                <el-radio label="0">{{ $t('sir') }}</el-radio>
                <el-radio label="1">{{ $t('lady') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('SalaryExpectation')" prop="salary">
              <el-input v-model="resume.data.salary"></el-input>
            </el-form-item>
            <el-form-item :label="$t('TelephoneNumber')" prop="mobile">
              <el-input v-model="resume.data.mobile"></el-input>
            </el-form-item>
            <el-form-item :label="$t('ResumeEmail')" prop="email">
              <el-input v-model="resume.data.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('fileUpload')" prop="file">
              <!-- <button @mousedown="dowm()" @mouseup="up()">鼠标事件</button> -->
              <el-upload
                class="upload-demo"
                :limit="1"
                drag
                :auto-upload="true"
                :on-success="onSuccess"
                :action="uploadUrl"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">{{ $t('fileUploadMsg') }}</div>
              </el-upload>
              <!-- <img  width="142" height="184" src="../../assets/img/jia.png" alt=""> -->
              <!-- <img  width="142" height="184" src="../../assets/img/hx.png" alt=""> -->
              <!-- <uploader class="uploader"  :limit="1"></uploader> -->
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addResumeFun()">{{
              $t('confirm')
            }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import uploader from '../uploader.vue'
import config from '@/http/config'

export default {
  name: 'joinUs',
  components: { uploader },
  data() {
    let validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('must')))
      } else {
        return callback()
      }
    }
    return {
      resume: {
        dialogFalg: false,
        data: {
          username: '',
          age: '',
          recruitId: '', // 岗位ID
          mobile: '', // 手机号
          email: '', // 邮箱
          sex: '', // 性别
          recruitPosition: '',
          salary: '', // 期望薪资
          cvUrl: '' // 附件简历地址
        }
      },
      workData: [],
      uploadUrl: config.baseUrl + '/officialweb/data/imageUpload',
      rules: {
        username: [{ validator: validateCode, trigger: 'blur' }],
        age: [{ validator: validateCode, trigger: 'blur' }],
        mobile: [{ validator: validateCode, trigger: 'blur' }],
        email: [{ validator: validateCode, trigger: 'blur' }],
        salary: [{ validator: validateCode, trigger: 'blur' }],
        sex: [{ validator: validateCode, trigger: 'blur' }],
        cvUrl: [{ validator: validateCode, trigger: 'blur' }]
      },
      // 导航数据
      navData: {
        navigationDescribe: '',
        navigationImg: '',
        navigationName: ''
      }
    }
  },
  created() {
    this.getNavImg()
    this.getData()
  },
  methods: {
    // 获取导航信息
    getNavImg() {
      this.$axios
        .post('/officialweb/data/getGwNavigation', {
          navigationType: '7'
        })
        .then((res) => {
          this.navData.navigationDescribe = res.data.navigationDescribe
          this.navData.navigationImg = res.data.navigationImg
          this.navData.navigationName = res.data.navigationName
        })
        .catch(() => {})
    },
    // 关闭简历投递
    closeResume() {
      this.resume.data = {
        name: '',
        age: '',
        tell: '',
        email: '',
        sex: '',
        recruitPosition: '',
        salary: '',
        file: ''
      }
      this.$refs.resume.resetFields()
      this.resume.dialogFalg = false
    },
    // 投递简历
    addResumeFun() {
      let isTrue = false
      this.$refs['resume'].validate((valid) => {
        if (!valid) {
          isTrue = true
        }
      })
      if (isTrue) return

      this.$axios
        .post('/officialweb/data/resume', this.resume.data)
        .then((res) => {
          if (res.code === 0) {
            alert('投递成功！')
          }
        })
        .catch(() => {})
      this.resume.dialogFalg = false
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let id = row.id + ''
      if (id.indexOf('C') != -1) {
        return {
          rowspan: 1,
          colspan: 3
        }
      }
    },
    //  打开投递简历页面
    openResumeFun(row) {
      this.resume.data.recruitPosition = row.recruitPosition
      this.resume.dialogFalg = true
      this.resume.data.recruitId = row.id
    },
    getData() {
      let page = { pageNo: 1, pageSize: 100 }
      this.$axios
        .post('/officialweb/data/findRecruitInfoList', page)
        .then((res) => {
          this.workData = res.list
        })
        .catch(() => {})
    },
    onSuccess(response, file, fileList) {
      let url = response.result.data
      if (url) {
        this.resume.data.cvUrl = String(url).replace('?source=preview', '')
      }
    }
  }
}
</script>

<style lang="less" scoped>

.d-none {
  display: none !important;
}

.el-button {
  outline: none;
  font-size: 18px;

  &:active {
    color: #606266;
    border-color: #dcdfe6;
  }

  &:focus {
    color: #606266;
    border-color: #dcdfe6;
    background-color: #fff;
  }

  &:hover {
    color: #3a8ee6;
    border-color: #3a8ee6;
  }
}

/deep/ .avatar-uploader .el-upload {
  width: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
}

/deep/ .el-upload-dragger {
  width: 260px;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

/deep/ .avatar {
  width: 150px;
  height: 150px;
  display: block;
}

.recruitRequire {
  padding-left: 20px;
  box-sizing: border-box;
}

.detail-box {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

// 设置投递简历
.resume {
  width: 100%;
  height: 100%;
  min-width: 860px;
  min-height: 400px;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
  animation: none;
  display: flex;
  flex-direction: column;

  /deep/ form.el-form.demo-ruleForm {
    display: flex;
    flex-wrap: wrap;
  }

  /deep/ .el-form-item {
    margin-bottom: 10px;
    width: 50%;
    text-align: left;

    &:last-child {
      margin-top: 20px;
    }
  }

  /deep/ .el-button--primary {
    background-color: #004ea2;
    padding: 10px 20px;

    &:hover {
      color: white;
      background-color: #006cdf;
    }
  }

  /deep/ .el-textarea {
    width: 260px;
  }

  /deep/ .el-dialog__body {
    padding-bottom: 10px;
  }

  /deep/ .el-input {
    margin-top: 10px;
    width: 260px;
  }

  /deep/ .el-dialog {
    border-radius: 20px;
    overflow: hidden;
    min-width: 860px;
    min-height: 550px;
  }

  /deep/ .el-dialog__header {
    border-radius: 20px 20px 0 0;
    background-color: #004ea2;
    background-blend-mode: multiply, multiply;
    padding: 10px;
    text-align: left;

    .el-dialog__title {
      color: white;
      margin-left: 8px;
      font-size: 18px;
    }
  }

  /deep/ button:focus {
    outline: none;
  }

  /deep/ .el-dialog__headerbtn {
    top: 8px !important;
    background: url(../../assets/img/hx.png) left no-repeat;
    background-size: cover;
  }

  /deep/ .el-dialog__headerbtn i {
    content: '修改下面的font-size可以改变图片的大小';
    font-size: 25px;
    visibility: hidden;
  }
}

.content {
  width: @mainSize;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 6.25rem;

  > .table {
    // 现在设置下拉的折叠边框
    /deep/ .el-table__row--level-1 {
      background-color: rgb(255, 255, 255);

      .el-table__cell {
        border-top: transparent !important;
      }
    }

    /deep/ .el-table__expanded-cell {
      border-top: transparent !important;
    }

    // 以下设置的el-table的边框
    /deep/ .el-table--border,
    .el-table--group {
      border: transparent;
    }

    /deep/ .el-table {
      border-right: 1px solid transparent !important;

      th {
        border-right: 1px solid transparent !important;

        .cell {
          font-size: 18px;
          font-weight: normal;
          color: #606266;
          padding: 12 0;
        }

        &:nth-child(5) {
          .cell {
            padding-left: 20px;
          }
        }
      }

      tr {
        border-right: 1px solid transparent !important;

        .cell {
          font-size: 18px;
        }

        &:last-child {
          td {
            border-bottom: 2px solid #edeff0 !important;
          }
        }
      }
    }

    /deep/ .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      content: '';
      background-color: transparent;
    }

    /deep/ .table th {
      border-color: transparent !important;
    }

    /deep/ tbody {
      tr {
        &:first-child {
          td.el-table__cell {
            border-top: transparent;
          }
        }
      }
    }

    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: 2px solid transparent;
      border-top: 2px solid #edeff0;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper
      .el-table--border.is-scrolling-left
      ~ .el-table__fixed {
      border-right: transparent;
      border-left: transparent;
      border-top: transparent;
      border-bottom: 2px solid #edeff0;
    }
  }
}
</style>
